<template>
    <div class="Lecturerend">
        <div class="Lectur_top">
            <el-button type="primary" size="small" @click="dialogVisible=true">创建课堂</el-button>
            <div class="fr">
                <div class="form-group form-horizontal">
                    <span>
                        <el-select v-model="value" placeholder="请选择" size="small" @change="Chroom(value)">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </span>
                    <span>
                        <el-input v-model="search" placeholder="请输入关键字" size="small"></el-input>
                    </span>
                    <span>
                        <el-button type="primary" size="small" @click="querys()">查询</el-button>
                    </span>
                    <span>
                        <el-button type="warning" size="small" @click="reset()">重置</el-button>
                    </span>
                </div>
            </div>
        </div>
        <!-- 表格 -->
        <div class="Lectur_table">
            <el-table 
                :data="tableData" 
                style="width: 100%;height:100%"
                stripe
                border
                :cell-style="{'text-align':'center'}"
                :header-cell-style="{background:'#f6f6f6',color:'#797979','text-align':'center'}"
            >   
                <el-table-column prop="name" label="名称"></el-table-column>
                <el-table-column prop="code" label="课堂码"></el-table-column>
                <el-table-column prop="users" label="学生总数"></el-table-column>
                <el-table-column prop="teams" label="分组数"></el-table-column>
                <el-table-column prop="unit" label="每组人数"></el-table-column>
                <el-table-column prop="state" label="状态">
                    <template slot-scope="scope">
                        <span>{{scope.row.state==1?'未开课':scope.row.state==2?'已开课':scope.row.state==3?'已结课':''}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="updateTime" label="创建时间"></el-table-column>
                <el-table-column label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button type="primary" plain size="mini" @click="comment(scope.row)">进入课堂</el-button>
                        <el-button type="danger" plain size="mini" @click="delBox(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 弹窗 -->
        <div class="Lectur_Popup">
            <el-dialog
                title="创建课堂"
                :visible.sync="dialogVisible"
                :show-close="false"
                width="600px"
                :before-close="handleClose">
                    <i class="el-icon-close closeis" @click="closei('ruleForm')"></i>
                    <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
                        <el-form-item label="课堂名称" prop="name">
                            <el-input v-model.number="ruleForm.name" placeholder="请输入课堂名称"></el-input>
                        </el-form-item>
                        <el-form-item label="分组方式" prop="type">
                            <el-radio-group v-model="ruleForm.type">
                                <el-radio :label="1">系统随机分组</el-radio>
                                <el-radio :label="2">线下自主分组</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="学生总数" prop="users">
                            <el-input v-model.number="ruleForm.users" placeholder="请输入学生总数"></el-input>
                        </el-form-item>
                        <el-form-item label="分组数" prop="teams">
                            <el-input v-model.number="ruleForm.teams" placeholder="请输入分组数"></el-input>
                        </el-form-item>
                        <div>默认进行平均分组，余数将进入最后一组</div>
                    </el-form>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="addroom('ruleForm')">确 定</el-button>
                    </span>
            </el-dialog>
        </div>
        
    </div>
</template>
<script>
export default {
    data () {
        return {
            dialogVisible:false,
            search:'',
            ruleForm:{
                name:'',
                type:'',
                users:'',
                teams:'',
            },
            rules:{
                name:[
                    { required: true, message: '请输入课堂名称', trigger: 'blur' }
                ],
                type:[
                    { required: true, message: '请选择分组方式', trigger: 'blur' }
                ],
                users:[
                    { required: true, message: '请输入学生总数', trigger: 'blur' },
                    { type: 'number', message: '学生总数必须为数字值'}
                ],
                teams:[
                    { required: true, message: '请输入分组数', trigger: 'blur' },
                    { type: 'number', message: '分组数必须为数字值'}
                ],
            },
            tableData:[],
            value:0,
            options:[
                {
                    value:0,
                    label:'全部'
                },
                {
                    value:1,
                    label:'未开课'
                },
                {
                    value:2,
                    label:'进行中'
                },
                {
                    value:3,
                    label:'已结课'
                },
            ],
            
        }
    },
    methods: {
        getapp(){
            this.axios.coursepage({
                token:this.$store.state.token,
                search:this.search,
                state:this.value
            }).then(res=>{
                if(res.data.code==0){
                    this.tableData = res.data.data.rows
                }else{
                    this.tableData = []
                }
            }).catch(err=>{

            })
        },
        // 筛选
        Chroom(val){
            if(val==0){
                this.getapp()
            }else{
                this.axios.coursepage({
                    token:this.$store.state.token,
                    search:this.search,
                    state:val
                }).then(res=>{
                    if(res.data.code==0){
                        this.tableData = res.data.data.rows
                    }else{
                        this.tableData = []
                    }
                }).catch(err=>{

                })
            }
        },
        reset(){
            this.value = 0
            this.search = ''
            this.getapp()
        },
        querys(){
            this.axios.coursepage({
                token:this.$store.state.token,
                search:this.search,
                state:this.value
            }).then(res=>{
                if(res.data.code==0){
                    this.tableData = res.data.data.rows
                }else{
                    this.tableData = []
                }
            }).catch(err=>{

            })
        },
        // 新建课堂
        addroom(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.token = this.$store.state.token
                    this.axios.coursecreate(
                        this.ruleForm
                    ).then(res=>{
                        if(res.data.code==0){
                            this.$message.success('创建成功')
                            this.dialogVisible = false
                            this.$refs[formName].resetFields();
                            this.getapp()
                        }else{
                            this.$message.info(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }else{
                    return false
                }
            })
        },
        // 进入课堂
        comment(rows){
            this.$router.push({name:'Detailsclassroom',query:{id:rows.id}})
        },
        // 删除课堂
        delBox(rows){
            this.$confirm('此操作永久删除该记录，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal:false
            }).then(() => {
                this.axios.coursedel({
                    token:this.$store.state.token,
                    id:rows.id
                }).then(res=>{
                    if(res.data.code==0){
                        this.getapp()
                    }
                }).catch(err=>{

                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        handleClose(done){

        },
        // 关闭弹窗
        closei(formName){
            this.dialogVisible = false
            this.$refs[formName].resetFields();
        },
    },
    mounted () {
        this.getapp()
    },
}
</script>
<style scoped>
@import './../../assets/css/teacher/Lecturerend.css';
</style>
<style>
.form-group .el-input__inner{
    width: 180px;
}
.Lectur_table .el-table--striped .el-table__body tr.el-table__row--striped td {
    background:#f4f8fb;
}
.Lectur_table .el-table__body-wrapper{
    height: calc(100% - 48px);
    overflow-y: auto;
}
/* .Lectur_table .el-table__empty-block{
    min-height: calc(100% - 48px);
} */
.Lectur_Popup .el-dialog{
    padding: 25px;
}
.Lectur_Popup .el-form-item{
    margin-bottom: 15px;
}
.Lectur_Popup .el-dialog__header{
    padding: 0;
    padding-bottom: 15px;
    border-bottom: 2px solid #e5e5e5;
}
.Lectur_Popup .el-dialog__body{
    border-bottom: 2px solid #e5e5e5;
    padding: 20px 0px;
}
.Lectur_Popup .el-form-item__label{
    line-height: 30px;
    font-size: 14px;
    font-weight: bold;
    color: #797979;
}
.Lectur_Popup .el-radio-group{
    width: 100%;
}
.Lectur_Popup .el-dialog__footer{
    text-align: center;
    padding:0;
    margin-top: 15px;
}

</style>